/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Helmet } from 'react-helmet';
import { colors } from '../styles/globals';
import DefaultLayout from '../containers/default';
import {
  ICMIcon,
  BandIcon,
  AIIcon,
  AppsIcon,
  CareIcon,
} from '../components/icons';
import {
  Section,
  SectionItem,
  SectionTitle,
  SectionDescription,
} from '../components/section';
import {
  Banner,
  BannerItem,
  BannerTitle,
  BannerDescription,
} from '../components/banner';

import '../styles/index.css';

export default () => (
  <DefaultLayout>
    <Helmet>
      <title>all.health Technology</title>
      <meta
        name="description"
        content="all.health Technology"
      />
    </Helmet>
    <Banner reverse backgroundColor={colors.blue}>
      <BannerItem left wide>
        <BannerTitle color={colors.white}>
          all.health is your continuous health management system
        </BannerTitle>
        <BannerDescription color={colors.white}>
          Our proprietary Integrated Continuous Management (ICM™) platform includes a
          state-of-the-art wearable band, software that is FDA-listed
          (Software as a Medical Device – SaMD) for monitoring vital signs, AI,
          and technology applications customized for either provider or patient users,
          all of which form a continuous link between patients and their care teams.
          Providers receive a platform that delivers a longitudinal view of data across
          their patient population. ICM leverages advanced data and analytics to offer
          comprehensive insight into health.
        </BannerDescription>
      </BannerItem>
      <BannerItem right isIcon>
        <ICMIcon />
      </BannerItem>
    </Banner>
    <Section>
      <SectionItem left isIcon backgroundColor={colors.grey1}>
        <BandIcon />
      </SectionItem>
      <SectionItem right>
        <SectionTitle>
          wearable band
        </SectionTitle>
        <SectionDescription>
          Our wristband is designed to blend in with users' lifestyles, built lightweight, and equipped with
          a battery that lasts several days. Users can journey through their everyday lives while the band's
          broad-spectrum light sensors collect and stream vital-sign data to the user's care team.
        </SectionDescription>
        <SectionDescription>
          Most wearables today use green light sensors to record wearers' heart rate, among
          other health indicators. all.health uses a broad light spectrum, including the first to use
          blue and purple light sensors, that not only make our health measurements more comprehensive
          and accurate, but it enables new types of measurements to scan for a broader array of disease risks.
        </SectionDescription>
      </SectionItem>
    </Section>
    <Section reverse>
      <SectionItem left>
        <SectionTitle>
          artificial intelligence
        </SectionTitle>
        <SectionDescription>
          Our machine learning models are developed using proprietary data collected in collaboration
          with top-tier research and academic institutions, public databases and clinical knowledge
          base with humans-in-the-loop. We use a myriad of AI methods including deep learning,
          transfer learning, graphic models as well as traditional machine learning approaches
          such as linear models and ensemble methods.
        </SectionDescription>
        <SectionDescription>
          We collaborate with providers and health systems to adapt our models to the established
          clinical workflow. Our algorithms combine human and machine intelligence to assess patient
          data and provide valuable insights and information to care teams and patients.
        </SectionDescription>
      </SectionItem>
      <SectionItem right isIcon backgroundColor={colors.purple}>
        <AIIcon />
      </SectionItem>
    </Section>
    <Section>
      <SectionItem left isIcon backgroundColor={colors.cyan}>
        <AppsIcon />
      </SectionItem>
      <SectionItem right>
        <SectionTitle>
          patient app /
          <br />
          provider platform
        </SectionTitle>
        <SectionDescription>
          all.health's software platform for providers and smartphone app for patients work together
          to make managing health easier. Visualizations and interactive modules fed by our AI models
          add context to data, so all parties can understand the next best step in a user's journey.
        </SectionDescription>
      </SectionItem>
    </Section>
    <Section reverse>
      <SectionItem left>
        <SectionTitle>
          care connectivity
        </SectionTitle>
        <SectionDescription>
          Our AI-powered system connects users with their local, trusted care providers to improve
          their health. With ICM, we empower users to take control of their health and help caregivers
          become more productive and effective beyond the walls of their office.
        </SectionDescription>
      </SectionItem>
      <SectionItem right isIcon backgroundColor={colors.sage}>
        <CareIcon />
      </SectionItem>
    </Section>
  </DefaultLayout>
);
